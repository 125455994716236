const maps = () => {
  document.addEventListener('DOMContentLoaded', function() {
    const locations = document.querySelectorAll(".location--wrapper");

    locations.forEach((location) => {
      const mapPointer = location.querySelector(".locator");
      mapPointer?.addEventListener("click", () => {
        const openedModal = document.querySelector(".location--wrapper.opened");
        console.log(openedModal);
        openedModal?.classList.remove("opened");
        location.classList.toggle("opened");
      });

      const closeBtn = location.querySelector(".close");
      closeBtn?.addEventListener("click", (e) => {
        e.preventDefault();
        location.classList.remove("opened");
      });
    });
    
  });
}

export { maps }