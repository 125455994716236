const accordion = () => {
  document.addEventListener('DOMContentLoaded', function() {
    const accordionSections = document.querySelectorAll(".accordion__section");

    accordionSections.forEach((section) => {
      const toggleButton = section.querySelector(".accordion__toggle");
      const content = section.querySelector(".accordion__content");
      const icon = section.querySelector(".accordion__icon");

      toggleButton?.addEventListener("click", function () {
        if (content && icon) {
          let closeToggle = false;
          const par = content.querySelector("p");
          if (par) {
            par.classList.toggle("active");
            closeToggle = par.classList.contains("active");
          }
          accordionSections.forEach((section) => {
            const content = section.querySelector(".accordion__content");
            const par = content?.querySelector("p");
            const icon = section.querySelector(".accordion__icon");
            if (par && icon) {
              par.classList.remove("active");
              par.style.maxHeight = "0";
              icon.classList.remove("close");
              icon.classList.add("plus");
            }
          });
          if(closeToggle) {
            if(par) {
              par.classList.add("active");
              par.style.maxHeight = par.scrollHeight + "px";
              console.log("here: ", par.scrollHeight);
              icon.classList.remove("plus");
              icon.classList.add("close");
            }
          } else {
            if(par) {
              par.classList.remove("active");
              par.style.maxHeight = "0";
              icon.classList.remove("close");
              icon.classList.add("plus");
            }
          }
        }

      });
    });


    // adjust min-height for accordion animation
    const accordions = document.querySelectorAll(".accordion") as NodeListOf<HTMLElement>;
    accordions.forEach((accordion) => {
      const acParent = accordion.closest(".fullscreen") as HTMLElement;
      if(acParent) {
        accordion.style.height = accordion.scrollHeight + 60 + "px";
      }

      // mobile splitscreen
      if(window.innerWidth < 1025) {
        const acSplitParent = accordion.closest(".splitscreen") as HTMLElement;
        console.log("sss", acSplitParent);
        if(acSplitParent) {
          accordion.style.height = accordion.scrollHeight + 60 + "px";
        }
      }
    });
  });
}

export { accordion }