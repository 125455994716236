import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import "swiper/scss";
import "swiper/scss/navigation";

const carousel = () => {
  document.addEventListener('DOMContentLoaded', function() {
    const carouselItems = document.querySelectorAll(".carousel .tile__item");

    function toggleItemState(item: HTMLElement, text: HTMLElement) {
      const button = item.querySelector(".tile-toggle__button");
      const itemOverlay = item.querySelector(".tile__overlay");
      if(button) {
        const isActive = button.getAttribute("data-toggle") === "active";
        button.setAttribute("data-toggle", isActive ? "inactive" : "active");
        if(isActive) {
          button.classList.add("plus");
          button.classList.remove("cross");
          itemOverlay?.classList.remove("active");
        } else {
          button.classList.add("cross");
          button.classList.remove("plus");
          itemOverlay?.classList.add("active");
        }
        // text.style.maxHeight = isActive ? "0" : "80vh";
      }
    }

    carouselItems.forEach((item) => {
      item.addEventListener("click", () => {
        const text = item.querySelector(".tile__text");
        if(text) {
          toggleItemState(item as HTMLElement, text as HTMLElement);
        }
      });
    });

    // let slidesPerView = Math.floor(carouselItems.length / 2);
    // if(carouselItems.length > 9) {
    //   slidesPerView = 4;
    // } else if(carouselItems.length < 10 && carouselItems.length > 6) {
    //   slidesPerView = 3;
    // } else {
    //   slidesPerView = 2;
    // }

    const swiper = new Swiper('.carousel', {
      modules: [Navigation, Pagination],
      speed: 600,
      centeredSlides: true,
      initialSlide: 1,
      spaceBetween: 15,
      // loop: true,
      freeMode: true,
      grabCursor: true,
      slidesPerView: 'auto',
      // breakpoints: {
      //   320: {
      //     slidesPerView: 'auto'
      //   },
      //   1024: {
      //     slidesPerView: slidesPerView
      //   }
      // },
      navigation: {
        nextEl: ".pagination-next",  
        prevEl: ".pagination-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        type: "fraction",
        renderFraction: function(currentClass, totalClass) {
          return `<span class="${currentClass}"></span> / <span class="${totalClass}"></span>`;
        }
      },
    });

  });
}

export { carousel }