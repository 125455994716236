import {QueryParam} from "./Classes/QueryParam";
import {FilterObj} from "./Classes/FilterObj";

const jobFilter = () => {

    const jobSearchBlocks = document.querySelectorAll('.job-search__search-block');
    jobSearchBlocks.forEach((jobSearchBlock) => {
        const id = jobSearchBlock.id;
        const jobOverviewPageUrl = jobSearchBlock.getAttribute('data-job-overview-page') ?? '';

        /**
         * Filter checked Buttons
         */
        const checkBoxes = jobSearchBlock?.querySelectorAll("input[type='checkbox']");
        checkBoxes.forEach((cBox) => {
            // Get parent option
            const dropDownOption = cBox.parentElement;
            const optionParent = dropDownOption?.parentElement;
            const applyButton = optionParent?.querySelector(".dropdown__apply-button");
            dropDownOption?.addEventListener('click', function(e) {
              const cbClicked = (<HTMLInputElement>e.target).checked;
              const checkBox = dropDownOption.querySelector(".dropdown__option-checkbox") as HTMLInputElement;
              checkBox.checked = !checkBox.checked; // toggle checkbox if option is clicked
              if(cbClicked !== undefined) {
                checkBox.checked = !(<HTMLInputElement>e.target).checked;
              }
              const originalChecked = checkBox.getAttribute('data-original-checked') == 'true';
              const currentChecked = checkBox.checked;
              if (originalChecked !== currentChecked) {
                checkBox.setAttribute('data-state-changed', 'true');
              } else {
                checkBox.setAttribute('data-state-changed', 'false');
              }

              // show/hide apply button
              const dropdownCheckboxes = optionParent?.querySelectorAll("input[type='checkbox']");
              const hasChecked = Array.prototype.slice.call(dropdownCheckboxes).some(cb => cb.checked);
              if(hasChecked) {
                applyButton?.classList.remove("inactive");
              } else {
                applyButton?.classList.add("inactive");
              }
            });
            /** checkBox.addEventListener('click', function (e) {
              console.log(e.target);
              if (e.target instanceof HTMLInputElement) {
                const originalChecked = e.target.getAttribute('data-original-checked') == 'true';
                const currentChecked = e.target.checked;
                if (originalChecked !== currentChecked) {
                  e.target.setAttribute('data-state-changed', 'true');
                } else {
                  e.target.setAttribute('data-state-changed', 'false');
                }
              }
            }); **/
        });

        /**
         * Filter Reset Button
         */
        const filterResetButtonList = jobSearchBlock?.querySelectorAll("button[data-button='filterReset']");
        const jobSearchID = jobSearchBlock?.parentElement?.id;
        filterResetButtonList.forEach((filterResetButton) => {
            filterResetButton?.addEventListener('click', function (e) {
                e.preventDefault();
                // redirect to job overview page
                location.href = jobOverviewPageUrl + "#" + jobSearchID;
            });
        });

        /**
         * Filter Exec Button
         */
        const filterExecuteButtonList = jobSearchBlock?.querySelectorAll("button[data-button='filterExecute']");
        filterExecuteButtonList.forEach((filterExecuteButton) => {
            filterExecuteButton?.addEventListener('click', function (e) {
              e.preventDefault();
              if (e.target instanceof HTMLElement) {
                const container = e.target.closest('#' + id) as HTMLElement;
                const changedOptions = container.querySelectorAll('[data-state-changed="true"]');
                if (changedOptions.length > 0) {
                  let dropDowns;
                  if(window.innerWidth < 1025) { // get mobile dropdowns
                    const filterMobile = container.querySelector(".filter__mobile");
                    dropDowns = filterMobile?.querySelectorAll('[data-dropdown]');
                  } else { // get large screen dropdowns
                    const filterDesktop = container.querySelector(".filter_desktop");
                    dropDowns = filterDesktop?.querySelectorAll('[data-dropdown]');
                  }
                  const filterObj = new FilterObj();
                  dropDowns?.forEach((dropDown) => {
                    const jobKey = dropDown.getAttribute('data-job-key') ?? '';
                    const queryParam = new QueryParam(jobKey);
                    const values = dropDown.querySelectorAll('input');
                    values.forEach((dropDown) => {
                      if (dropDown.checked && dropDown.value.length > 0) {
                        queryParam.add(dropDown.value);
                      }
                    });
                    filterObj.addQuery(queryParam);
                  });
                  if (filterObj.hasOptions) {
                    filterObj.setOverviewPageUrl(jobOverviewPageUrl);
                    if(jobSearchID) filterObj.setAnchorLink("#" + jobSearchID);
                    filterObj.redirectUrl();
                  }
                }
              }
            });
        });
    });


}


export {jobFilter}