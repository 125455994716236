const logos = () => {
  document.addEventListener('DOMContentLoaded', function() {
    const interactiveLogos = document.querySelectorAll(".logos-box");

    interactiveLogos.forEach((interactiveLogo) => {
      const interactiveContent = interactiveLogo.querySelector(".logos-content") as HTMLElement;
      const interactiveLogoImg = interactiveContent.querySelector(".logo--wrapper") as HTMLElement;
      const interactiveWrapper = interactiveContent.querySelector(".logo-interactive--wrapper") as HTMLElement;
      const h = interactiveWrapper.clientHeight + 30 + "px";
      const lh = interactiveLogoImg.clientHeight + "px";
      interactiveWrapper.style.height = "0";
      interactiveLogo.addEventListener("mouseover", () => {
        if(interactiveWrapper) {
          interactiveWrapper.style.height = h;
          interactiveLogoImg.style.opacity = "0";
          interactiveLogoImg.style.height = "0";
          interactiveLogoImg.style.overflow = "hidden";
        }
      });
      interactiveLogo.addEventListener("mouseout", () => {
        if(interactiveWrapper) {
          interactiveWrapper.style.height = "0";
          interactiveLogoImg.style.opacity = "1";
          interactiveLogoImg.style.height = lh;
          interactiveLogoImg.style.overflow = "unset";
        }
      });
    });
    
  });
}

export { logos }