
const jobSearch = () => {
  document.addEventListener('DOMContentLoaded', function () {
    const filterButton = document.querySelector('.job-search__filter-mobile');
    const overlay = document.querySelector('.overlay');
    const closeButton = overlay?.querySelector('.overlay__close-button');

    filterButton?.addEventListener('click', function (e) {
      e.preventDefault();
      // overlay?.classList.add('overlay--active','overlay--opening');
      // document.querySelector('body')?.classList.add('no-scroll');

      // overlay?.addEventListener('animationend', () => {
      //   overlay?.classList.remove('overlay--opening');
      // }, {once: true });
      overlay?.classList.add('overlay--active');
    });

    closeButton?.addEventListener('click', function (e) {
      e.preventDefault();
      // overlay?.classList.remove('overlay--opening');
      // overlay?.classList.add('overlay--closing');

      // overlay?.addEventListener('animationend', () => {
      //   overlay?.classList.remove('overlay--active','overlay--closing');
      //   document.querySelector('body')?.classList.remove('no-scroll');
      // }, {once: true });
      overlay?.classList.remove('overlay--active');
    });

    const jobSearches = document.querySelectorAll('.job-search__search-block');
    jobSearches.forEach((jsBlock) => {
      const clearFilterButton = jsBlock.querySelector(".job-search__radio-column");
      const checkBoxes = jsBlock?.querySelectorAll("input[type='checkbox']");
      const hasChecked = Array.prototype.slice.call(checkBoxes).some(cb => cb.checked);
      if(hasChecked) {
        clearFilterButton?.classList.remove("inactive");
      } else {
        clearFilterButton?.classList.add("inactive");
      }
    });
  });

  document.addEventListener('DOMContentLoaded', function() {
    const checkboxContainers = document.querySelectorAll('.checkbox-container');
  
    checkboxContainers.forEach(function(checkboxContainer) {
      const checkbox = checkboxContainer.querySelector('.checkbox-container__checkbox') as HTMLInputElement;
  
      checkboxContainer.addEventListener('click', function() {
        checkbox.checked = !checkbox.checked;
        // trigger click event to start listener from job-filter.ts
        const event = new Event("click");
        checkbox.dispatchEvent(event);
      });
    });
  });

  
}



export { jobSearch }