class QueryParam {
    name: string;
    values: Array<string>;
    hasOptions: boolean

    constructor(name: string) {
        this.name = name;
        this.values = [];
        this.hasOptions = false;
    }

    add(value: string) {
        this.values.push(value);
        this.hasOptions = true;
    }

    getValuesAsCommaSeperatedList() {
        return this.values.join(',');
    }
}

export {QueryParam}