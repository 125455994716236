
const video = () => {
  document.addEventListener('DOMContentLoaded', function () {
    
    // video height
    const fullscreen = document.querySelectorAll(".fullscreen") as NodeListOf<HTMLElement>;
    if(fullscreen) {
      fullscreen.forEach((fscreen) => {
        const videoContainer = fscreen.querySelector(".video-container") as HTMLElement;
        const video = fscreen.querySelector(".video-container__video") as HTMLElement;
        if(videoContainer && video) {
          const video_width = video.getBoundingClientRect().width;
          fscreen.style.minHeight = video_width * (9/16) + "px";
          window.addEventListener("resize", function() {
            const video_width = video.getBoundingClientRect().width;
            fscreen.style.minHeight = (video_width * (9/16)) + "px";
          })
        }
      });
    }

  });
  
}



export { video }