
const footer = () => {
  document.addEventListener('DOMContentLoaded', function () {
    const languageToggle = document.querySelector(".footer__langs");
    if(languageToggle) {
      languageToggle.querySelector('.noop')?.addEventListener('click', (e: Event) => {
        // direct a-href button should have no effect
        e.preventDefault();
      });
      languageToggle.addEventListener("click", function(e) {
        const isClosed = languageToggle.classList.contains("closed");
        if(isClosed) {
          languageToggle.classList.remove("closed");
          languageToggle.classList.add("opened");
        } else {
          languageToggle.classList.remove("opened");
          languageToggle.classList.add("closed");
        }
      })
    }

  });
  
}



export { footer }