import "../sass/index.scss";

import { jobSearch } from "./blocks/job-search";
import { jobFilter } from "./jobs/job-filter";
import { accordion } from "./blocks/accordion";
import { timeline } from "./blocks/timeline";
import { carousel } from "./blocks/carousel";
import { jobWidget } from "./blocks/job-widget";
import { heroGallery } from "./blocks/hero-gallery";
import { gallerySlider } from "./blocks/gallery-slider";
import { dropdown } from "./partials/dropdown";
import { text } from "./blocks/text";
import { textTiles } from "./blocks/text-tiles";
import { header } from "./blocks/header";
import { footer } from "./blocks/footer";
import { logos } from "./blocks/logos";
import { maps } from "./blocks/maps";
import { pageHeader } from "./blocks/page-header";
import { pageHeaderContainer } from "./blocks/page-header-container";
import { video } from "./blocks/video";

jobSearch();
accordion();
carousel();
jobWidget();
heroGallery();
gallerySlider();
dropdown();
text();
textTiles();
timeline();
header();
footer();
logos();
maps();
pageHeader();
video();
pageHeaderContainer();

// start temporarily add since :has pseudo-class doesn't work on firefox
document.addEventListener('DOMContentLoaded', function () {
  const logoElems = document.querySelectorAll(".logos-block--container.logo-two--columns");
  logoElems.forEach((logoElem) => {
    logoElem.parentElement?.classList.add("logo-height__mobile");
  });
  const bannerElems = document.querySelectorAll(".banner-layout");
  bannerElems.forEach((bannerElem) => {
    const childrenCount = bannerElem.children[0].children.length;
    if(childrenCount == 1) {
      bannerElem.classList.add("banner-layout--one-child");
    }
  });

  // add class if page-header has 1 or 2 columns
  const pageHeaderElems = document.querySelectorAll(".page-header-container");
  pageHeaderElems.forEach((pageHeaderElem) => {
    const acfContainer = pageHeaderElem.querySelector(".acf-innerblocks-container");
    const childrenCount = acfContainer?.children.length;
    if(childrenCount == 2) {
      pageHeaderElem.classList.add("page-header--split");
    }
    if(childrenCount == 1) {
      pageHeaderElem.classList.add("page-header--full");
    }
  });

  jobFilter();
});
// end