const text = () => {
  document.addEventListener('DOMContentLoaded', function() {

    const scrollableElements = document.querySelectorAll("[data-scrollable-text]") as NodeListOf<HTMLElement>;
    scrollableElements.forEach((element) => {
      if(element.parentElement) {
        element.parentElement.dataset.sticky = "";
      }
      const upperPart = element.querySelector("[data-text-module-upper]") as HTMLElement;
      const bottomPart = element.querySelector("[data-text-module-bottom]") as HTMLElement;
      window.addEventListener("scroll", () => {
        // if(window.innerWidth < 1025) return;
        const windowHeight = window.innerHeight;
        const ratio = (bottomPart.getBoundingClientRect().top -  upperPart.getBoundingClientRect().top)/(windowHeight * 0.95);
        //upperPart.style.opacity = `${ratio*2 - 1}`;
      });
    });

    // banner with background image scrollable
    if(window.innerWidth < 1025) {
      const banners = document.querySelectorAll(".banner-layout");
      banners.forEach((banner) => {
        const backgrounds = banner.querySelectorAll(".background-img, .background-video");
        backgrounds.forEach((background) => {
          const img = background.querySelector("img");
          const vid = background.querySelector("video");
          // if(img) img.style.height = background.scrollHeight + 10 + "px";
          // if(vid) vid.style.height = background.scrollHeight + 10 + "px";
        });
      });
    }

  });
}

export { text }