
const header = () => {
  document.addEventListener('DOMContentLoaded', function () {
    const menuToggle = document.querySelector('.header__menu-toggle');
    const menuUL = document.querySelector('.header__menu');
    const headerMenus = document.querySelectorAll(".header__menu-item");
    const jobWidget = document.querySelector('[data-job-widget]') as HTMLElement;
    const headerLogo = document.querySelector(".header__logo") as HTMLElement;
    const mobileNavBackground = document.querySelector(".mobile-nav__background") as HTMLElement;
    
    if(menuToggle && menuUL) {
      const toggleIcon = menuToggle.querySelector('.icon-bars-filter > img') as HTMLImageElement;
      menuToggle.addEventListener('click', function (e) {
        e.preventDefault();
        const isActive = menuToggle.getAttribute("data-toggle") === "active";
        menuToggle.setAttribute("data-toggle", isActive ? "closed" : "active");
        document.querySelector('body')?.classList.toggle('no-scroll');
        if(jobWidget) {
          jobWidget.style.display = isActive ? "block" : "none";
        }
        if(headerLogo) {
          headerLogo.style.display = isActive ? "flex" : "none";
        }
        if(toggleIcon) {
          const iconSrc = isActive ? "burger-23x23.svg" : "close-23x23.svg";
          const assetDir = toggleIcon.src.substring(0, toggleIcon.src.lastIndexOf('/'));
          toggleIcon.src = assetDir + "/" + iconSrc;
        }
        if(mobileNavBackground) {
          mobileNavBackground.style.display = isActive ? "none": "flex";
        }
        menuUL?.classList.toggle('active');
      });
    }

    headerMenus.forEach((menu) => {
      const subMenuItems = menu.querySelector(".sub__menu");
      const subMenuToggle = menu.querySelector('.sub__menu-toggle');
      const subToggleIcon =  subMenuToggle?.querySelector('.icon-arrow > img') as HTMLImageElement;

      if(subMenuToggle) {
        if(window.innerWidth < 1025) {
          subMenuToggle.addEventListener("click", function(event) {
            event.preventDefault();
            const prevActive = document.querySelector(".sub__menu.active");
            
            if(subMenuItems && subToggleIcon) {
              let closeToggle = false;
              subMenuItems.classList.toggle('active');
              closeToggle = subMenuItems.classList.contains("active");

              const assetDir = subToggleIcon.src.substring(0, subToggleIcon.src.lastIndexOf('/'));

              if(closeToggle) {
                // subToggleIcon.textContent = "-";
                subToggleIcon.src = assetDir + "/arrow-up-light-23x23.svg";
              } else {
                // subToggleIcon.textContent = "+";
                subToggleIcon.src = assetDir + "/arrow-down-light-23x23.svg";
              }

              // hide previous active menu
              if(prevActive) {
                const prevActiveToggle = prevActive.parentElement?.querySelector('.sub__menu-toggle');
                const prevActiveToggleIcon = prevActiveToggle?.querySelector(".icon-arrow > img") as HTMLImageElement;
                prevActiveToggleIcon.src = prevActiveToggleIcon.src.substring(0, prevActiveToggleIcon.src.lastIndexOf('/')) + "/arrow-down-light-23x23.svg";  
                prevActive?.classList.remove('active');
              }
            }
          });
        }
      }
      
      // hide submenu on page scroll
      if(window.innerWidth > 1024) {
        const activeMenu = menuUL?.querySelector(".menu__active");
        if(activeMenu?.classList.contains("parent_menu__active") || activeMenu?.closest(".header__menu-item")?.classList.contains("parent_menu__active")) {
          const headerMenuLinks = menu.querySelector("a");
          let scrollDistance = 0, endScroll = 0, diff = 0;
          window.addEventListener("scroll", function() {
            scrollDistance = Math.round(window.scrollY);
            diff = scrollDistance - endScroll;
            if(diff >= 50) {
              headerMenuLinks?.classList.add("hide-on-scroll");
              menu.classList.add("height-0");
            }
            if(diff <= -50) {
              headerMenuLinks?.classList.remove("hide-on-scroll");
              menu.classList.remove("height-0");
            }
          });
          window.addEventListener("scrollend", function() {
            endScroll = scrollDistance;
          })
        }
      }

      // hide logo on page scroll mobile
      if(window.innerWidth < 1025) {
        const headerLogo = document.querySelector(".header__logo");
        if(headerLogo) {
          let scrollDistance = 0, endScroll = 0, diff = 0;
          window.addEventListener("scroll", function() {
            scrollDistance = Math.round(window.scrollY);
            diff = scrollDistance - endScroll;
            if(diff >= 50) {
              headerLogo.classList.add("hidden");
            }
            if(diff <= -50 || diff == 0) {
              headerLogo.classList.remove("hidden");
            }
          });
          window.addEventListener("scrollend", function() {
            endScroll = scrollDistance;
          });

          // scrollend alternative
          const debounce = function (func: () => void, delay: number): () => void {
            let timer: number | undefined;

            return function () {
              if (timer !== undefined) {
                clearTimeout(timer as any);
              }
              timer = setTimeout(func, delay) as any;
            };
          };
          // scrollend if stopped scrolling for 200ms
          window.addEventListener("scroll", debounce(() => {
            endScroll = scrollDistance;
          }, 200));

          
        }
      }

      // hide menu behind footer
      // if(window.innerWidth < 1025) {
      //   const footerBrands = document.querySelector(".footer--brands") as HTMLElement;
      //   const footerDetails = document.querySelector(".footer--details") as HTMLElement;
      //   const hMenu = document.querySelector(".header__menu") as HTMLElement;

      //   let rootMargin = -100;
      //   let options = {
      //     rootMargin: "-100px",
      //     threshold: 0.0,
      //   };

      //   const navCallback = () => {
      //     const brandsTop = footerBrands.getBoundingClientRect().top - window.innerHeight - rootMargin;
      //     const detailsTop = footerDetails.getBoundingClientRect().top - window.innerHeight - rootMargin;
      //     if(brandsTop < 0 && detailsTop < 0) {
      //       hMenu.classList.add("opacity-0");
      //       hMenu.classList.add("below");
      //       hMenu.classList.remove("above");
      //     } else if(brandsTop < 0 && detailsTop > 0) {
      //       if(hMenu.classList.contains("below")) {
      //         hMenu.classList.remove("opacity-0");
      //       } else if(jobWidget.classList.contains("above")){
      //         hMenu.classList.add("opacity-0");
      //       }
      //     } else if(brandsTop > 0 && detailsTop > 0) {
      //       hMenu.classList.remove("opacity-0");
      //       hMenu.classList.add("above");
      //       hMenu.classList.remove("below");
      //     } 
      //   }

      //   let navObserver = new IntersectionObserver(navCallback, options);
      
      //   navObserver.observe(footerBrands);
      //   navObserver.observe(footerDetails);
      // }
    });

  });
  
}



export { header }