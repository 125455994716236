import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import "swiper/scss";
import "swiper/scss/navigation";

const heroGallery = () => {
  document.addEventListener('DOMContentLoaded', function() {
    const heroItems = document.querySelectorAll(".hero-gallery .tile__item");

    function toggleItemState(item: HTMLElement, text: HTMLElement) {
      const button = item.querySelector(".tile-toggle__button");
      const itemOverlay = item.querySelector(".tile__overlay");
      if(button) {
        const isActive = button.getAttribute("data-toggle") === "active";
        button.setAttribute("data-toggle", isActive ? "inactive" : "active");
        if(isActive) {
          button.classList.add("plus");
          button.classList.remove("cross");
          itemOverlay?.classList.remove("active");
        } else {
          button.classList.add("cross");
          button.classList.remove("plus");
          itemOverlay?.classList.add("active");
        }
        // text.style.maxHeight = isActive ? "0" : "80vh";
      }
    }

    heroItems.forEach((item) => {
      item.addEventListener("click", () => {
        const text = item.querySelector(".tile__text");
        if(text) {
          toggleItemState(item as HTMLElement, text as HTMLElement);
        }
      });
    });
    
    if(window.innerWidth < 1025) {
      const swiper = new Swiper('.hero-gallery', {
        modules: [Navigation],
        speed: 600,
        centeredSlides: true,
        spaceBetween: 15,
        slidesPerView: 'auto',
        initialSlide: 1,
        freeMode: true,
        grabCursor: true,
        navigation: {
          nextEl: ".tile__next",  
          prevEl: ".tile__prev",
        }
      });
    }

  });
}

export { heroGallery }