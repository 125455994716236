const dropdown = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const dropdowns = document.querySelectorAll('[data-dropdown]') as NodeListOf<HTMLElement>;
    dropdowns.forEach((dropdown) => {
      const dropdownSearch = dropdown.querySelector('[data-dropdown-search]') as HTMLInputElement;
      const dropdownOptions = dropdown.querySelectorAll('[data-dropdown-option]') as NodeListOf<HTMLElement>;
      const dropdownErrorMessage = dropdown.querySelector('[data-dropdown-error-message]') as HTMLElement;
      const dropdownErrorMessageString = dropdown.querySelector('[data-dropdown-error-message-string]') as HTMLElement;
      if(dropdownSearch && dropdownOptions && dropdownErrorMessage && dropdownErrorMessageString) {
        dropdownSearch.addEventListener('input', () => {
          const searchValue = dropdownSearch.value;
          let count = 0;
          dropdownOptions.forEach((option) => {
            const optionValue = option.textContent;
            if(optionValue) {
              if(optionValue.toLowerCase().includes(searchValue.toLowerCase())) {
                option.style.display = 'flex';
                count++;
              } else {
                option.style.display = 'none';
              }
            }
          });
          if (count === 0) {
            dropdownErrorMessage.style.display = 'block';
            dropdownErrorMessageString.textContent = searchValue;
          } else {
            dropdownErrorMessage.style.display = 'none';
          }
        });
      }

      const dropdownButton = dropdown.querySelector('[data-dropdown-button]') as HTMLElement;
      const dropdownContent = dropdown.querySelector('[data-dropdown-content]') as HTMLElement;
      dropdownButton.addEventListener('click', () => {
          if(dropdownContent) {
            if(dropdown.dataset.dropdownOpen === 'true') {
              dropdown.dataset.dropdownOpen = 'false';
            } else {
              closeDropdowns();
              dropdown.dataset.dropdownOpen = 'true';
            }
          }
      });
            
    });

    // close other dropdowns
    function closeDropdowns() {
      dropdowns.forEach((dropdown) => {
        if(dropdown.dataset.dropdownOpen === 'true') {
          dropdown.dataset.dropdownOpen = 'false';
        }
      });  
    }
  });
}; 

export { dropdown };