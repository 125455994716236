const pageHeaderContainer = () => {
    document.addEventListener('DOMContentLoaded', function() {
      const pageHeaders = document.querySelectorAll(".page-header-container");
      pageHeaders.forEach((pageHeader) => {
        const arrowDown = pageHeader?.querySelector(".page-header__next-section");
        console.log(arrowDown);

        if(arrowDown) {
          arrowDown.addEventListener("click", function(e) {
            console.log(pageHeader.getBoundingClientRect());
            e.preventDefault();
            window.scroll({
              left: 0,
              top: pageHeader.getBoundingClientRect().bottom + window.scrollY,
              behavior: "smooth"
            });
          });
        }
      });
  
      
    });
  }
  
  export { pageHeaderContainer }