const pageHeader = () => {
  document.addEventListener('DOMContentLoaded', function() {
    const pageHeader = document.querySelector(".page-header");
    const arrowDown = pageHeader?.querySelector(".page-header__next-section");

    if(arrowDown) {
      arrowDown.addEventListener("click", function(e) {
        e.preventDefault();
        window.scroll({
          left: 0,
          top: pageHeader?.clientHeight,
          behavior: "smooth"
        });
      });
    }
    
  });
}

export { pageHeader }