const jobWidget = () => {
  document.addEventListener('DOMContentLoaded', () => {

    const footerBrands = document.querySelector(".footer--brands") as HTMLElement;
    const jobWidget = document.querySelector("[data-job-widget]") as HTMLElement;
    const footerDetails = document.querySelector(".footer--details") as HTMLElement;
    let rootMargin = 0;
    let options = {
      rootMargin: "",
      threshold: 0.0,
    };

    if(window.innerWidth < 1025) {
      rootMargin = -100;
      options.rootMargin = rootMargin + "px";
    } else {
      rootMargin = -30;
      options.rootMargin = rootMargin + "px";
    };

    const callback = () => {
      const brandsTop = footerBrands.getBoundingClientRect().top - window.innerHeight - rootMargin;
      const detailsTop = footerDetails.getBoundingClientRect().top - window.innerHeight - rootMargin;
      if(brandsTop < 0 && detailsTop < 0) {
        // jobWidget.style.opacity = "0";
        jobWidget.classList.add("hidden");
        jobWidget.classList.add("below");
        jobWidget.classList.remove("above");
      } else if(brandsTop < 0 && detailsTop > 0) {
        if(jobWidget.classList.contains("below")) {
          // jobWidget.style.opacity = "1";
          jobWidget.classList.remove("hidden");
        } else if(jobWidget.classList.contains("above")){
          // jobWidget.style.opacity = "0";
          jobWidget.classList.add("hidden");
        }
      } else if(brandsTop > 0 && detailsTop > 0) {
        // jobWidget.style.opacity = "1";
        jobWidget.classList.remove("hidden");
        jobWidget.classList.add("above");
        jobWidget.classList.remove("below");
      } 
    }

    let observer = new IntersectionObserver(callback, options);
   
    if(footerBrands) {
      observer.observe(footerBrands);
      observer.observe(footerDetails);
    }

    window.addEventListener("resize", () => {
      const marginBefore = options.rootMargin;
      if(window.innerWidth < 1025) {
        rootMargin = -100;
        options.rootMargin = rootMargin + "px";
      } else {
        rootMargin = -30;
        options.rootMargin = rootMargin + "px";
      };
      if(marginBefore !== options.rootMargin) {
        observer.unobserve(footerBrands);
        observer.unobserve(footerDetails);
        observer = new IntersectionObserver(callback, options);
        observer.observe(footerBrands);
        observer.observe(footerDetails);
      }
    });
  

    if (jobWidget) {
      if(window.innerWidth < 1025) {
        jobWidget.classList.add('job-widget--closed');
      }
      const jobWidgetClose = jobWidget.querySelector('[data-job-widget-close]');
      if (jobWidgetClose) {
        jobWidgetClose.addEventListener('click', () => {
          jobWidget.classList.add('job-widget--closed');

          jobWidget.addEventListener('transitionend', () => {
            jobWidget.classList.add('no-display');
          }, { once: true });
        });
      }

      const jobWidgetOpen = jobWidget.querySelector('[data-job-widget-open]');
      if (jobWidgetOpen) {
        jobWidgetOpen.addEventListener('click', () => {
          jobWidget.classList.remove('job-widget--closed');
          jobWidget.classList.add('manual-opened');
          jobWidget.classList.remove('no-display'); // remove if widget opened
          resetAnimation();
        });
      }

      window.addEventListener("scroll", () => {
        if(window.scrollY >= 150) {
          if(!jobWidget.classList.contains('manual-opened')) {
            jobWidget.classList.add('job-widget--closed');
            jobWidget.addEventListener('transitionend', () => {
              jobWidget.classList.add('no-display');
            }, { once: true });
          }
        }
      });
    }
  });

  
  const resetAnimation = function() {
    const jobWidgetContent = document.querySelector(".job-widget__content") as HTMLElement;
    jobWidgetContent?.classList.remove("job-widget__content");
    void jobWidgetContent?.offsetWidth;
    jobWidgetContent?.classList.add("job-widget__content");
  }
}

export { jobWidget }
