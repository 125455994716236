import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import "swiper/scss";
import "swiper/scss/navigation";

const gallerySlider = () => {
  document.addEventListener('DOMContentLoaded', function() {
    const swiper = new Swiper('.gallery-slider', {
      modules: [Navigation],
      speed: 600,
      slidesPerView: 'auto',
      freeMode: true,
      initialSlide: 1,
      spaceBetween: 15,
      // loop: true,
      centeredSlides: true,
      grabCursor: true,
      navigation: {
        nextEl: ".tile__next",  
        prevEl: ".tile__prev",
      }
    });
  });
}

export { gallerySlider }