import { QueryParam } from "./QueryParam";

class FilterObj {
    data: QueryParam[] = [];
    hasOptions: boolean;
    overviewPageUrl: URL;
    anchorLink: string;

    constructor() {
      this.hasOptions = false;
      this.overviewPageUrl = new URL(location.pathname, location.origin);
      this.anchorLink = "";
    }

    addQuery(query: QueryParam) {
      if (query.hasOptions) {
        this.hasOptions = true;
        this.data.push(query);
      }
    }

    setOverviewPageUrl(value: string) {
      this.overviewPageUrl = new URL(value);
    }

    setAnchorLink(value: string) {
      this.anchorLink = value;
    }

    redirectUrl() {
      const params = new URLSearchParams();
      this.data.forEach((queryParam: QueryParam, i) => {
        params.set(`filter_by[${queryParam.name}]`, queryParam.getValuesAsCommaSeperatedList());
      });

      const mark = params.size > 0 ? '?' : '';
      location.href = this.overviewPageUrl + mark + params + this.anchorLink;
    }
}


export {FilterObj}