const textTiles = () => {
  document.addEventListener('DOMContentLoaded', function() {

    const scrollableElements = document.querySelectorAll("[data-scrollable-text-tiles]") as NodeListOf<HTMLElement>;
    scrollableElements.forEach((element) => {
      if(element.parentElement) {
        element.parentElement.dataset.sticky = "";
      }
    });
    
  });
}

export { textTiles }