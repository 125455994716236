const timeline = () => {
  document.addEventListener('DOMContentLoaded', function() {
      const scrollableElements = document.querySelectorAll('[data-scrollable-timeline]') as NodeListOf<HTMLElement>;
      scrollableElements.forEach((element) => {
        if(element.parentElement) {
          element.parentElement.dataset.sticky = "";
        }
      });

      scrollableElements.forEach((el) => {
        const timelineContent = el.querySelector("[data-timeline-content]") as HTMLElement;
        const timelineScroll = el.querySelector("[data-timeline-scroll]") as HTMLElement;
        const timelineContainer = el.closest(".fullscreen, .splitscreen") as HTMLElement;

        // add justify-content: flex-start to container of timeline
        const acfBlock = timelineContainer.querySelector(".acf-innerblocks-container");
        if(acfBlock) {
          acfBlock.classList.add("flex-start");
        }

        window.addEventListener("resize", () => {
          if(window.innerWidth < 1025) {
            timelineScroll.style.transform = `translateX(0px)`;
            timelineContainer.style.marginBottom = "0px";
          }
        });

        window.addEventListener("scroll", () => {
          // if(window.innerWidth < 1025) return;
          const timelinePosition = el.getBoundingClientRect().top;
          const timelineHeight = el.getBoundingClientRect().height;
          const contentPosition = timelineContent.getBoundingClientRect().top;
          const contentHeight = timelineContent.getBoundingClientRect().height;
          const ratio = (contentPosition - timelinePosition)/(timelineHeight - contentHeight);
          timelineScroll.style.transform = `translateX(calc(${ratio*100}vw - ${ratio*100}%))`;
        });
      });

  });

}

export { timeline }